import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';

import {Container, Row, Col} from 'reactstrap';

import Link from '../net/Link';
import Label from '../components/Label';
import moment from 'moment';
import { graphql } from 'gatsby';

class ArticleOverviewTemplate extends React.Component {

render() {
  const { data } = this.props;

  let edges = this.props.data.allMarkdownRemark.edges;

  // first article is the newest one, we use it in the "new!" area
  let headline = edges[0].node.frontmatter.title;
  let date =  moment(edges[0].node.frontmatter.date).format("YYYY~MM~DD");
  let link = edges[0].node.frontmatter.path;
  let lead = edges[0].node.frontmatter.abstract;
  
  let content = '';
  let title = '';
  let abstract = '';
  let image = '';

  // find the overview page content
  edges.map(edge => {
    if (edge.node.frontmatter.path === '/articles/') {
      content = edge.node.html;
      title = edge.node.frontmatter.title;
      abstract = edge.node.frontmatter.abstract;
      image = edge.node.frontmatter.featureImage || "";
    }
  });

  let render = (content !== '');
  return (

    <Layout>
      <SEO title={title} description={abstract} image={image} />
      
      <div>
        <Container fluid className="content-page block">
        <Row>
          <Col>
          
          <h1>Articles</h1>
          </Col>
          </Row>

          <Row>
            <Col>
            
          <Link to={link}>
          <Label text="NEW!"/>
          <h4>Newest Article: {headline}<span class="date">{date}</span></h4>
          <p class="lead">{lead}</p>
          </Link>
          </Col>
        </Row>
        <div style={{marginBottom:"100px"}}/>



        <Row>
            <Col>
            { render ? 
              <div dangerouslySetInnerHTML={{ __html: content }} />
              :
              <div>There is nothing to see here.</div>
            }
            </Col>
          </Row>
        </Container>
    </div>
      </Layout>
  )
}

}
export default ArticleOverviewTemplate;
/*
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        featureImage
        abstract
        downloads {
          to
          text
        }
      }
    }
  }
  `
  */


  /*
  Very hacky: We query simply all articles, because we're interested in two things:
  One, the overview page, which has the path '/articles/' and two, the first article in this list, which
  is the one with the newest date.
  */
export const pageQuery = graphql`
query {
allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {path: {regex: "/articles/"}, date: {}, lang: {}}}) {
edges {
node {
  id
  excerpt(pruneLength: 250)
  html
  frontmatter {
    date
    path
    featureImage
    title
    abstract
  }
}
}
}}`